import { instanceAbtRouter } from '@shein-aidc/basis-abt-router'
import commonAppPoskey from './config/common.js'
import options from './config/index'

export const abtRouter = (abtservice) => {
  instanceAbtRouter({ 
    abtServer: abtservice,
    options,
    newPosKeys: commonAppPoskey.newPosKeys,
    posKeys: commonAppPoskey.posKeys,
    preloadModel: true,
  })
}
