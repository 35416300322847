export default {
  routeName: 'cart',
  newPosKeys: [
    'CartQuickshipVisual',
    'componentswitch',
    'CartRecommendTab',
    'shopbagtab1', // 给推荐 cccx 预请求
    'shopbagtab2',
    'shopbagtab3',
    'shoppingCartPage',
    'pageBeshared',
  ],
  posKeys: [
    'RecommendExpandPop',
    'AllListQuickShip',
    'SellingPoint',
    'EmptyCartYouMayAlsoLike',
    'EmptyShopBagFloor',
  ],
}
