// 收藏
export default [
  {
    routeName: 'UserWishlist',
    newPosKeys: [
      'WishlistInStock', 
      'componentswitch', 
      'favoriteListEmptyPage',
      'favoriteListPage',
      'collectionBoardsEmptyPage',
      'collectionBoardsPage',
      'WishlistsoldoutSimilar', 
      'WishlistSimilarcomparison',
      'Wishlistpricecut',
      'Wishlisttoporder',
      'WishlistReco',
      'RealtimeFeedbackJson',
      'WishlistRecoPopup',
      'wishlistAddToBag',
      'Cartentranceinfo',
      'WishlistOnePicChange'
    ],
    posKeys: [
      'Branch',
      'WishlistOnePic', // 收藏列表一行一图
      'AllListQuickShip',
      'Quickregclosed',
      'newPrivacy',
      'WishItemSize',
      'Movepoints',
      'Similaritems',
    ],
  },
  {
    routeName: 'UserWishlistGroup',
    newPosKeys: [
      'componentswitch', 
      'WishLoadmore', 
      'WishlistsoldoutSimilar', 
      'WishlistSimilarcomparison',
      'WishlistReco',
      'RealtimeFeedbackJson',
      'WishlistRecoPopup',
      'wishlistAddToBag',
      'Cartentranceinfo'
    ],
    posKeys: ['Similaritems', 'WishlistOnePic'],
  }
]
