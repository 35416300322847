// 使用文档： wiki.pageId=1062032837

import { Abt } from '@shein-aidc/basis-abt'
import schttp from 'public/src/services/schttp'

const formatClientData = (data) => {
  Object.keys(data).forEach((key) => {  
    const item = data[key] || {}
    item.posKey = item.poskey
    item.param = item.p
    item.sceneStr = key
  })
  return data
}

export default function createInstance() {
  const abt = new Abt({ schttp: async (data) => {
    if (typeof window === 'undefined') {
      // 这里服务端场景，不清楚， ssr时 有问题 触发。
      return {}
    }
    try {
      const result = await schttp({
        url: '/api/abt/userinfo/query',
        method: 'post',
        data,
      })
      return formatClientData(result.params)
    } catch(e) {
      console.error(e)
      return {}
    }
  } })
  return abt
}
