// 分类页

export default {
  routeName: 'page_category',
  posKeys: [
    // 'NaviAllTab',
    'CccGirlsCategory',
    'CccMenCategory',
    'CccGuysCategory',
    'CccKidsCategory',
    'CccHomeCategory',
    'CccPlussizeCategory',
    'CccAlltabCategory',
    'NaviRec',
    'CccxCategoryTypeList',
    'CccxSwimwearCategory',
    'CccxSaleCategory',
    'CccxBeautyCategory',
    'CccxOtherCategory',  
  ],
  newPosKeys: [
    'CccxCategoryStyle',
    'NaviRecJson',
    'recSwitch',
  ],
}
