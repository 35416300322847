export default {
  routeName: 'page_advertising',
  newPosKeys: [
    'AdLandingRecommendCate',
    'AdLandingRecommendCateJson',
    'AdLandingRecommendOther',
    'AdLandingRecommend'
  ],
  posKeys: [],
}
