export default [
  'page_rank_products',
].map(routeName => {
  return {
    routeName,
    preload: true, // 是否预加载， 切换路由时，不会重新请求数据
    newPosKeys: [
      'bestdiscountvolume',
    ],
    posKeys: [
      
    ],
  }
})
