// 客户端-详情页abt配置

export default {
  routeName: 'page_goods_detail',
  preload: true, // 是否预加载， 切换路由时，不会重新请求数据
  newPosKeys: [
    'drankinglabelclick',
    'recSwitch',
    'Mastertest',
    'Imagewatermark',
    'ProductDetailBelowPolicyFloorJson',
    'pwaBestsellerCategories',
    'OutDetailBestseller',
    'OftenBoughWithJson',
    'GetTheLookJson',
    'OutfitRecommendEntry',
    'OutfitRecommendLand',
    'CouponBagUITest',
    'picnewcart',
    'onlyxleft',
    'SizeAttributeEntrance',
    'hotnews',
    'UnderPrice',
    'ShippingLogisticsTime',
    'selectcolor',
    'ShowPromotion',
    // 'SellPoint',
    // 'RankAggrega',
    // 'PriceLocat',
    // 'PriceOneLine',
    'DetailPictures',
    'QuickShow',
    'Interestpointstyle',
    'PromotionalBelt',
    'storebrandseparate',
    'storewishlist',
    'pricemember',
    'detailshowsellerinfo',
    'moresellerinfo',
    '3Psellerdelivery',
    'ProductDetailRecommend',
    'ymalrecommend',
    'RankingList',
    'RecoLoadmore',
    'detailCropping',
    // 'NewDetailPictures',
    'EstimatedPrice',
    'EstimatedShowType',
    'discountLabel',
    'greysellingPoint',
    'BrandCollectionAdjust',
    'goodsPicAb',
    'manyrecommend',
    'AdultProductAge',
    'CollectionGoodsRec',
    'FrequentBoughtTogether',
    'SimilarItem',
    'soldoutsimilar',
    'CccDetailVideo',
    'similaritemsnew',
    'PersonalizedRecommend',
    'addnewhotJson',
    'addbagpopupgoods',
    'addbagpopupcates',
    'DetailFamilyOutfits',
    'FollowLabel',
    'GoodsdetailSizeguideNew',
    'detailonetwoTitle',
    'rankingDetail',
    'drankinglabelclick',
    'detailrecCard', // 是否开启二图新商卡v3
    'recmultiCard', // 是否开启多图新商卡v3
    'DetailStarReview',
    'detailbehaviorLabel',
    'detailReview',
    'selectedreview',
    'selectedreviewJson',
    'detailKeyAttribute',
    'RecoOneClickPay',
    'detailgoodsCard',
    'FlashSaleSubtype',
    'salesLabel',
    'picturepwacut',
    'ProductDetailsLabel',
    'longsize',
    'rankinghomePage',
    'homePageStarReview',
    'homepagebehaviorLabel',
    'homepageReview',
    'homepageKeyAttribute',
    'cccdescription',
    'vimeomute',
    // 'FunctionsuSpended',
    'detailbanner',
    'PicSearchUpgrade',
    'PicSearchStrategy',
    // 'FunctionsuSpended',
    'soldoutsimilarstyle', // 缺货推相似弹窗样式
    'newPrice',
    'carddiscountLabel',
    'listflashSale',
    'IsPerformance',
    'buytogether',
    'BuyBoxSwitch',
    'newoutfit',
    'listrankingTag',
    'listtagSorting',
    'detailcountdown',
    'SearchHotGD',
    'listquickship', // 新quickship标签
    'listquickshipKey',
    'paydiscountshowdetail',
    'Promotionrankdetial',
    'storeflash',
    'goodsdetailsSKCprefix',
    'goodssaleattributefold',
    'collestrategy',
    'Quickinterestpoint',
    'storeccc',
    'listattributeLabels',
    'pwapicture',
    'localwarehouse',
    'listattributeLabels',
    'obwProductDetailRecommend',
    'DetailQuickShip'
  ],
  posKeys: [
    'ProductDetailBelowPolicyFloor',
    'ymalshopseriesbrand',
    'Storegoodspicture',
    'DetailShowCouponsPickingRules',
    'LocalReviews',
    'PointProgram',
    // 'DetailCouponEntrance', // 已推全
    'DetailPageQuickShipShow',
    // 'Sellingattri', // 已推全
    'Movepoints',
    'CccGoodsdetail',
    'Shippingtime',
    // 'SmilarSyte', // 已废弃
    'GetTheLook',
    'SearchSuggestwordFeedback',
    'shipping',
    'Selfsizerecommended', //  是否接入自有尺码推荐
    // 'familycouple', // 已推全
    // 'ShowGetTheLook',
    'brandstore',
    'brandstoreobm',
    // 'ShowVariedLabel',
    'ReportItem',
    'NoSkuClick',
    'Reviewlabel',
    'GoodsdetailToppicOutfitRecommend',
    // 'BeautyFindation',
    'SubFlashSale',
    // 'ColorPopup',
    // 'GoodsPreferredSeller',
    'AllListQuickShip',
    'CccGoodsDetails',
    'NewStyleYouMayAlsoLike',
    // 'YouMayAlsoLikeMultiColor',
    'youmayalsolikeaddbag',
    'NEWaddcart',
    'ProductDetailYouMayAlsoLike',
    'ClothingDetailOftenBoughWith',
    'SellingPoint',
    'RecommendExpandPop',
    'OutProductDetailYouMayAlsoLike',
    'AddShowGroup',
    // 'LookBookNew',
    // 'FunctionsuSpended',
    'MostPopular',
    'PicSearch',
    'shipping'
  ],
}
