// 使用文档： wiki.pageId=1062032837

import createInstance from './core/index.js'
import { abtRouter } from './abtRouter.js'
import schttp from 'public/src/services/schttp'

const _log = (name, data = '') => console.log(`%c ${name}`, 'background: #000; color: #fff; padding: 4px; font-size: 14px;', data)

const abtservice = (function getABTServiceInstance () {
    if (typeof window === 'undefined') {
      return createInstance()
    }

    if (!window._abt_server_provider) {
      const abtInstance = createInstance()
      window._abt_server_provider = abtInstance
      abtRouter(abtInstance)
      abtHook(abtInstance)
      _abtDebugHook()
    }
    return window._abt_server_provider
})()

function abtHook(abt) {
  window.abtHook = (...arg) => { // 业务中不要使用哦。仅供测试使用，不然你会被打的
    return abt.getUserAbtResult(...arg).then(res => {
      _log('current abt data:', res)
      return res
    })
  }
  // 埋点钩子
  window.abtAnalysisHook = (...arg) => {
    const { sa } = abt.getUserAbtResultForAnalysis(...arg)
    _log('The obtained data is:', sa)
    return { sa }
  }
}

function _abtDebugHook() {
  if (!gbCommonInfo.isDebug) return // 只有debug模式下才会开启

  const abtMockHookObj = {}
  ;['get','set', 'clear', 'delete'].forEach(method => {
    abtMockHookObj[method] = async (data) => {
      const params = {}
      if (typeof data === 'string') {
        params.posKeys = data
      } else if (data && typeof data === 'object') {
        Object.keys(data).forEach(key => {
          const item = data[key]
          if (item.param && !item.p) {
            item.p = item.param
          }
          if (item.p && !item.param) {
            item.param = item.p
          }
        })
        params.data = data
      }
      const url = `/api/abt/mock/${method}`
      const res = await schttp({
        url,
        method: 'post',
        data: params
      })
      return res
    }
  })

  const _devTools = {
    info: {
      version: '1.0.4',
      text: '已支持无痕模式mock'
    },
    abtMock: abtMockHookObj,
    debug: async (data) => {
      let params = {}
      if (typeof data === 'string') {
        params = { newPosKeys: data }
      } else {
        const { newPosKeys, posKeys } = data
        params = {
          newPosKeys,
          posKeys
        }
      }
      if (params.posKeys) {
        const WEB_BRAND = gbCommonInfo.WEB_CLIENT === 'romwe' ? 'R' : 'S'
        const TERMINAL = 'M'
        const prefix = `${WEB_BRAND}${TERMINAL}`
        params.posKeys = params.posKeys.split(',').map((posKey) => `${prefix}${posKey}`).join(',')
      }
      return schttp({
        url: '/api/abt/test/query',
        method: 'post',
        data: params
      }).then(({ response: data }) => {
        const { curlCommand: curl, traceId } = data?.originData?.[0] || {}
        _log('Request result', data.data)
        _log('curl of the current original request', curl)
        return { data: data.data, curl, traceId }
      })
    },
  }

  Object.defineProperty(window, 'abtDevTools', {
    get() {
      return _devTools
    }
  })

  window.abtMockHook = (...arg) => {
    abtDevTools.abtMock.set(...arg).then(res => {
      _log('abtMock set success 请刷新页面生效！' , res)
    })
  }
  window.abtDebugHook = abtDevTools.debug
}

export {
  abtservice,
}
