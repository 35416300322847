export default {
  routeName: 'orderReturnNew',
  newPosKeys: [
    'returnVideo', 
    'RefundAmountDetails', 
    'showCombineReturn',
    'gifcardExpirednotice',
    'accountRequired',
    'noReturn',
    'isReturnReasonShow',
    'ReturnAndRefund',
  ],
  posKeys: [],
}
