export default {
  routeName: 'page_store',
  preload: true, // 是否预加载
  newPosKeys: [
    'storesearchefficiencyoptimize',
    'listattributeLabels',
    'storepdpfloat',
    'brandrecommendBrand',
    'StorePerformanceSpaHybration'
  ],
}
