// 直播页abt配置

export default {
  routeName: 'page_live',
  // preload: true, // 是否预加载， 切换路由时，不会重新请求数据
  newPosKeys: [
    'SheinLiveCloudStreaming',
    'LiveCartAttraction',
    'LiveCartIcon',
    'LiveAddCartBullet',
    'LiveFlashTime',
    'LiveNotCheckout',
  ],
}
